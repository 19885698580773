import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import moment from "moment-timezone";

class DayList extends Component {
  state = { selectedMonth: "" };

  analyzeDates(numDays, rules, daysClosed = []) {
    const dayList = [...Array(numDays)]
      .map((x, i) => i)
      .map((daysAdded) => {
        const meta = {};
        const addedMoment = moment().add(daysAdded, "day");
        meta.formattedDate = addedMoment.format("DD-MM-YYYY");
        meta.formattedDateFriendly = addedMoment.format("dddd, MMMM Do, YYYY");
        meta.calendarDate = addedMoment.calendar(null, {
          sameDay: "[Today]",
          nextDay: "[Tomorrow]",
          nextWeek: "dddd, Do",
          sameElse: "L",
        });
        meta.dayNumber = addedMoment.format("dd");
        meta.dayName = addedMoment.format("dddd");
        meta.dayMonth = addedMoment.format("MMMM");
        meta.dayYear = addedMoment.format("YYYY");

        //
        meta.isClosed =
          !rules[meta.dayName] ||
          !rules[meta.dayName].opens ||
          daysClosed.includes(addedMoment.format("DD-MM")) ||
          daysClosed.includes(addedMoment.format("DD-MM-YYYY")) ||
          daysClosed.includes(addedMoment.format("YYYY-MM-DD"));
        meta.message =
          meta.isClosed || daysClosed.includes(addedMoment.format("YYYY-MM-DD"))
            ? ` - Closed`
            : "";
        return meta;
      });
    return dayList;
  }
  render() {
    const bookingsByDate = {};
    this.props.bookings.forEach((booking) => {
      const day = moment(booking.estimated_call_time).format("DD-MM-YYYY");
      bookingsByDate[day] = bookingsByDate[day] || 0;
      bookingsByDate[day]++;
    });

    const block = {};
    this.analyzeDates(
      this.props.branch.settings.ticketing.advance_ticketing_gap,
      this.props.branch.settings.operating_hours,
      this.props.branch.settings.days_closed
    ).forEach((day, offset) => {
      block[`${day.dayMonth} ${day.dayYear}`] =
        block[`${day.dayMonth} ${day.dayYear}`] || [];
      block[`${day.dayMonth} ${day.dayYear}`].push({ day, offset });
    });

    return (
      <div className={"month-container"}>
        <select
          className={"uk-select"}
          onChange={(evt) => {
            this.setState({
              selectedMonth:
                this.state.selectedMonth === evt.target.value
                  ? ""
                  : evt.target.value,
            });
          }}
        >
          <option value={""}>Pick the Month and Year</option>
          {Object.keys(block).map((monthYear) => (
            <option key={monthYear} value={monthYear}>
              {monthYear}
            </option>
          ))}
        </select>
        {Object.keys(block).map((monthTitle) => {
          return (
            <Fragment key={monthTitle}>
              <div
                className={
                  this.state.selectedMonth === monthTitle
                    ? "uk-padding days-container"
                    : ""
                }
              >
                <ul className={"uk-list day-list"}>
                  {block[monthTitle].map(({ day, offset }) => (
                    <li
                      className={[
                        this.props.request.preferred_date === day.formattedDate
                          ? "qm-selected"
                          : day.isClosed
                          ? "uk-text-muted"
                          : "",
                        `${day.dayMonth} ${day.dayYear}` ===
                        this.state.selectedMonth
                          ? ""
                          : "uk-hidden",
                      ].join(" ")}
                      key={day.formattedDate}
                      onClick={() => {
                        if (!day.isClosed)
                          this.props.setDate(day.formattedDate);
                      }}
                    >
                      <span>
                        {offset < 7
                          ? day.calendarDate
                          : `${day.formattedDateFriendly}`}
                      </span>{" "}
                      <em> &nbsp; {day.message}</em>
                      {bookingsByDate[day.formattedDate] && (
                        <>
                          &nbsp;
                          <em className={"muted"}>
                            ({bookingsByDate[day.formattedDate]} bookings)
                          </em>
                        </>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            </Fragment>
          );
        })}
      </div>
    );

    // return (
    //   <div className={"month-container"}>
    //     <ul className={"uk-list uk-list-striped"}>
    //       {this.props.branch &&
    //         this.analyzeDates(
    //           this.props.branch.settings.ticketing.advance_ticketing_gap,
    //           this.props.branch.settings.operating_hours,
    //           this.props.branch.settings.days_closed
    //         ).map((day, offset) => {
    //           let header = null;
    //           if (lastMonthYear !== `${day.dayMonth} ${day.dayYear}`) {
    //             header = (
    //               <li
    //                 key={`${day.dayNumber} ${day.dayName} ${day.dayMonth} ${day.dayYear}`}
    //                 className={"calendar-header"}
    //                 onClick={() => {
    //                   this.setState({
    //                     selectedMonth:
    //                       this.state.selectedMonth ===
    //                       `${day.dayMonth} ${day.dayYear}`
    //                         ? ""
    //                         : `${day.dayMonth} ${day.dayYear}`,
    //                   });
    //                 }}
    //               >
    //                 {`${day.dayMonth} ${day.dayYear}`}
    //               </li>
    //             );
    //           }
    //
    //           lastMonthYear = `${day.dayMonth} ${day.dayYear}`;
    //
    //           return (
    //             <Fragment
    //               key={`inner-key-${offset}-${day.dayName} ${day.dayMonth} ${day.dayYear}`}
    //             >
    //               {(this.props.request.preferred_date === day.formattedDate ||
    //                 !this.props.request.preferred_date) && (
    //                 <Fragment>
    //                   {header}
    //                   <li
    //                     className={[
    //                       this.props.request.preferred_date ===
    //                       day.formattedDate
    //                         ? "qm-selected"
    //                         : day.isClosed
    //                         ? "uk-text-muted"
    //                         : "",
    //                       `${day.dayMonth} ${day.dayYear}` ===
    //                       this.state.selectedMonth
    //                         ? ""
    //                         : "uk-hidden",
    //                     ].join(" ")}
    //                     key={day.formattedDate}
    //                     onClick={() => {
    //                       if (!day.isClosed)
    //                         this.props.setDate(day.formattedDate);
    //                     }}
    //                   >
    //                     <span>
    //                       {offset < 7
    //                         ? day.calendarDate
    //                         : `${day.formattedDateFriendly}`}
    //                     </span>{" "}
    //                     <em> &nbsp; {day.message}</em>
    //                   </li>
    //                 </Fragment>
    //               )}
    //             </Fragment>
    //           );
    //         })}
    //     </ul>
    //   </div>
    // );
  }
}

const mapStateToProps = function (state) {
  return {
    request: state.request,
    branch: state.branch,
  };
};

const mapDispatchToProps = function (dispatch) {
  return {
    setDate: (preferredDate) => dispatch({ type: "SET_DATE", preferredDate }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(DayList);
